<script>
  import linkMixin from '@/mixins/linkMixin'

  /**
   * A styled link or a button styled like a link, optionally with an icon.
   */
  export default {
    mixins: [linkMixin],
    props: {
      /** The link’s type. When left `undefined`, the type is determined automatically based on the presence of link props (`to` or `href`). */
      type: {
        type: String,
        default: undefined,
        validator: (value) => ['link', 'button'].includes(value),
      },
      /** The text color. */
      color: {
        type: String,
        default: 'primary',
        validator: (value) =>
          ['primary', 'gray', 'black', 'white', 'dawn', 'summer'].includes(value),
      },
      /** The font size. */
      fontSize: {
        type: String,
        default: undefined,
        validator: (value) => ['2xs', 'xs', 'sm', 'base', 'lg'].includes(value),
      },
      /** The font weight. */
      fontWeight: {
        type: String,
        default: undefined,
        validator: (value) => ['medium', 'semibold', 'bold'].includes(value),
      },
      /** The position of the icon relative to the text. */
      iconPosition: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'right'].includes(value),
      },
      /** The size of the icon, in Tailwind units. */
      iconSize: {
        type: Number,
        default: 4,
      },
      /** Whether the link is inline or a standalone. */
      inline: {
        type: Boolean,
        default: false,
      },
      /** Whether the link is underlined */
      underlined: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      finalType() {
        if (this.type === undefined) {
          if (this.hasLink) {
            return 'link'
          } else {
            return 'button'
          }
        }
        return this.type
      },
      component() {
        switch (this.finalType) {
          case 'button':
            return 'button'
          case 'link':
            return 'BaseLink'
          default:
            return undefined
        }
      },
      propsToPassDown() {
        switch (this.finalType) {
          case 'button':
            return {
              type: 'button',
            }
          case 'link':
            return this.linkProps
          default:
            return {}
        }
      },
      hasUnderline() {
        // Standalone links should have an underline, unless they have an icon
        return this.underlined || (!this.inline && !this.$scopedSlots.icon)
      },
      componentClasses() {
        return [
          'link',
          `link--${this.color}`,
          !this.inline ? 'inline-block' : '',
          '-mx-1',
          'px-1',
          'rounded-sm',
          // purgecss: text-2xs text-xs text-sm text-base text-lg
          this.fontSize !== undefined ? `text-${this.fontSize}` : '',
          // purgecss: font-medium font-semibold font-bold
          this.fontWeight !== undefined ? `font-${this.fontWeight}` : '',
          this.hasUnderline ? 'underline' : '',
          'cursor-pointer',
        ].join(' ')
      },
      iconClasses() {
        return ['inline-block', 'align-middle'].join(' ')
      },
    },
  }
</script>

<template>
  <!-- for purgecss: link--primary link--gray link--black link--white link--dawn link--summer -->
  <component
    :is="component"
    :class="componentClasses"
    v-bind="{ ...propsToPassDown, ...$attrs }"
    v-on="$listeners"
    ><BaseIcon
      v-if="$scopedSlots.icon && iconPosition === 'left'"
      :size="iconSize"
      :class="iconClasses"
      style="top: -0.1em; margin-right: 0.4em"
    >
      <!-- @slot Optional. An icon shown next to the button’s label. Should contain an SVG icon component. -->
      <slot name="icon" /> </BaseIcon
    ><!-- @slot The link’s label. Should contain text. --><slot /><BaseIcon
      v-if="$scopedSlots.icon && iconPosition === 'right'"
      :size="iconSize"
      :class="iconClasses"
      style="top: -0.1em; margin-left: 0.4em"
    >
      <slot name="icon" /> </BaseIcon
  ></component>
</template>

<style lang="postcss" scoped>
  .link {
    &--primary {
      @apply text-primary;

      &:focus {
        @apply bg-green-active shadow-outline-primary-50;
      }

      &:hover {
        @apply bg-green-hover;
      }

      &:active {
        @apply text-primary-dark;
      }
    }

    &--gray {
      @apply text-gray;

      &:focus {
        @apply bg-gray-active shadow-outline-gray-50;
      }

      &:hover {
        @apply bg-gray-hover;
      }

      &:active {
        @apply text-gray-darkest;
      }
    }

    &--black {
      @apply text-black;

      &:focus {
        @apply bg-gray-active shadow-outline-gray-50;
      }

      &:hover {
        @apply bg-gray-hover;
      }

      &:active {
        @apply text-gray-darkest;
      }
    }

    &--white {
      @apply text-white;
    }

    &--dawn {
      @apply text-dawn;
    }

    &--summer {
      @apply text-summer;
    }
  }
</style>
