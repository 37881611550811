import { DIRECTION_AUTO, DIRECTION_NONE, DIRECTION_BACK, DIRECTION_FORWARD } from '@/router'

export default {
  props: {
    /** The link’s `vue-router` route destination. Cannot be used in combination with `href`. */
    to: {
      type: [String, Object],
      default: undefined,
    },
    /** Only if `to` is used. The direction to navigate toward. */
    direction: {
      type: String,
      default: DIRECTION_AUTO,
      validator: (value) =>
        [DIRECTION_AUTO, DIRECTION_NONE, DIRECTION_BACK, DIRECTION_FORWARD].includes(value),
    },
    /** The link’s URL destination. Cannot be used in combination with `to`. */
    href: {
      type: String,
      default: undefined,
    },
    /** The link’s target. Either undefined (link opens in the same tab) or `_blank` (link opens in a new tab). */
    target: {
      type: String,
      default: undefined,
      validator: (value) => ['_blank'].includes(value),
    },
  },
  computed: {
    hasLink() {
      return this.to !== undefined || this.href !== undefined
    },
    linkProps() {
      if (!this.hasLink) {
        return {}
      }
      return {
        to: this.to,
        direction: this.direction,
        href: this.href,
        target: this.target,
      }
    },
  },
}
